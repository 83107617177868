import differenceBy from 'lodash/differenceBy'
import remove from 'lodash/remove'
import { SPLIT_EVENT } from './eventTrackerFunction'
import { getExpConfig } from '../utils/configService'
import { SPLIT_FEATURES } from '../utils/constants'
import {
  splitCollectionThreshold,
  splitImpressionTypes,
  splitThresholdList,
} from './splitImpressionsTracker'

/**
 * NOTE - readyToTrack, queuedToTrack, isTracking are designed to hold
 * multiple values for future scope.
 *
 * Future scope - We will add banner impression tracking in future.
 * The banners would be required to track multiple times
 *
 */

// max objects collected for tracking at a time per impression type
const collectionThreshold = {
  PRODUCT_IMPRESSION: 100,
  HOMEPAGE_PRODUCT_IMPRESSION: 500,
  BANNER_IMPRESSION: 40,
  OTHER_BANNER_IMPRESSION: 100,
  RECIPE_IMPRESSION: 100,
  ...splitCollectionThreshold,
}

const checkpoints = {
  PAYLOAD_BREAK_LIMIT: 20,
  LEFTOVER_LIMIT: 15,
}

let impressionType = ''
let splitImpressionType = ''

/**
 * @param {*} currentType
 * returns Boolean
 * True if
 * 1. total & current viewed elements count matches
 * 2. current count matches to threshold as per impression type
 */
const hasReachedPerEventItemThreshold = (
  currentType = impressionTypes.PRODUCT_IMPRESSION
) => {
  const currentCount = readyToTrack[currentType].length
  const thresholdList = {
    PRODUCT_IMPRESSION: 20,
    HOMEPAGE_PRODUCT_IMPRESSION: 20,
    BANNER_IMPRESSION: 5,
    OTHER_BANNER_IMPRESSION: 5,
    RECIPE_IMPRESSION: 10,
    PROMO_IMPRESSION: 1, // unable to batch promo impressions with GA4 so keeping it as 1
    WEEKLY_PROMO_IMPRESSION: 10,
    ...splitThresholdList,
  }

  let thresholdReached = false
  if (currentCount >= thresholdList[currentType]) {
    return true
  }

  return thresholdReached
}

/**
 * holds possible impressions types
 * Future scope - add impressions for banners
 */
const impressionTypes = {
  PRODUCT_IMPRESSION: 'PRODUCT_IMPRESSION',
  HOMEPAGE_PRODUCT_IMPRESSION: 'HOMEPAGE_PRODUCT_IMPRESSION',
  BANNER_IMPRESSION: 'BANNER_IMPRESSION',
  OTHER_BANNER_IMPRESSION: 'OTHER_BANNER_IMPRESSION',
  CITRUS_BANNER_IMPRESSION: 'CITRUS_BANNER_IMPRESSION',
  CITRUS_PRODUCT_IMPRESSION: 'CITRUS_PRODUCT_IMPRESSION',
  RECIPE_IMPRESSION: 'RECIPE_IMPRESSION',
  PROMO_IMPRESSION: 'PROMO_IMPRESSION',
  WEEKLY_PROMO_IMPRESSION: 'WEEKLY_PROMO_IMPRESSION',
  ...splitImpressionTypes,
}

/**
 * holds possible tracking types
 * Future scope - add multiple time tracking for a given element
 */
const trackingTypes = {
  TRACK_ONCE: 'TRACK_ONCE',
}

// holds all possible elements to track as per impression type
let elementsToTrack = {}

// holds all tracked elements as per impression type
// this is maintained since user can change store & still see same products / banners
// this eliminates the need for .unobserve() method of intersection observer
let trackedElements = {}

// holds elements to be tracked upon triggering tracking call
let readyToTrack = {}

//hold count of untracked elements per impression type
let pendingToTrackCount = {}

const resetLocalObjects = () => {
  elementsToTrack = {}
  pendingToTrackCount = {}
  trackedElements = {}
}

/**
 * indentifies if given target is visible in viewport
 *
 * NOTE: removed observer.unobserve(entry.target) since we need to maintain it
 * in case user switches store
 * Tracking only once per page or store type is achieved using trackedElements object
 */
const trackIntersectionOnce = entries => {
  const retriggerImpressionFlag = getExpConfig(
    SPLIT_FEATURES.FE_RETRIGGER_IMPRESSION
  )
  const retriggerImpressioEnabled = retriggerImpressionFlag?.treatment === 'on'
  entries.forEach(entry => {
    if(retriggerImpressioEnabled){
      if(entry.intersectionRatio >= .5 && (entry.target.eligibleTrack === undefined || entry.target.eligibleTrack)){
        processRetrack(entry.target.dataset.refid, 
          entry.target.dataset.impressiontype,
          `SPLIT_${entry?.target?.dataset?.impressiontype}`
        )
        setImpressionData(
          entry.target.dataset.refid,
          entry.target.dataset.impressiontype,
          `SPLIT_${entry?.target?.dataset?.impressiontype}`,
          retriggerImpressioEnabled
        )
        entry.target.eligibleTrack= false
      }
      if(entry.intersectionRatio === 0){
        entry.target.eligibleTrack= true
      }
    } else {
      if(entry.intersectionRatio >= .5){
        setImpressionData(
          entry.target.dataset.refid,
          entry.target.dataset.impressiontype,
          `SPLIT_${entry?.target?.dataset?.impressiontype}`,
          retriggerImpressioEnabled
        )
      }
    }
  })
}

const processRetrack =(refId,impressiontype, splitImpressionType)=> {
  //check if item if in trackedElements
   // if not found then check if item is in readyToTrack
  const element = (trackedElements[impressiontype] || []).find(item => item.refId === refId) || (readyToTrack[impressiontype] || []).find(item => item.refId === refId) 
  const splitElement = (trackedElements[splitImpressionType]|| []).find(item => item.refId === refId) || (readyToTrack[splitImpressionType]|| []).find(item => item.refId === refId)
  if(element) {
    setReferenceTrackingData(element, impressiontype)
  }
  if(splitElement){
    setReferenceTrackingData(splitElement, splitImpressionType)
  }
}

// holds possible call function list as per tracking type
const callbackFnList = {
  [trackingTypes.TRACK_ONCE]: trackIntersectionOnce,
}

// Pubsub to trigger tracking event
const triggerImpressionsTracking = {
  events: {},
  subscribe: function (eventName, fn) {
    if (!this.events[eventName] && typeof fn === 'function') {
      this.events[eventName] = fn
    }
  },
  publish: function (eventName, data) {
    if (
      this.events[eventName] &&
      typeof this.events[eventName] === 'function'
    ) {
      this.events[eventName](data)
    }
  },
  unsubscribe: function (eventName) {
    if (this.events[eventName]) {
      delete this.events[eventName]
    }
    resetLocalObjects()
  },
}

const setCitrusTrackingObjects = currentEleImpressionType => {
  const objectTypeToSet =
    currentEleImpressionType === impressionTypes.BANNER_IMPRESSION
      ? impressionTypes.CITRUS_BANNER_IMPRESSION
      : impressionTypes.CITRUS_PRODUCT_IMPRESSION

  if (!Object.keys(elementsToTrack).includes(objectTypeToSet)) {
    elementsToTrack[objectTypeToSet] = []
  }

  if (!Object.keys(readyToTrack).includes(objectTypeToSet)) {
    readyToTrack[objectTypeToSet] = []
  }
}

const setCitrusImpressionsData = (currentEleImpressionType, eleAdId = '') => {
  const readyToTrackType =
    currentEleImpressionType === impressionTypes.BANNER_IMPRESSION
      ? impressionTypes.CITRUS_BANNER_IMPRESSION
      : impressionTypes.CITRUS_PRODUCT_IMPRESSION
  readyToTrack[readyToTrackType].push(eleAdId)
}

//Split - initialising tracking objects
const setSplitTrackingObjects = splitImpressionType => {
  if (!Object.keys(elementsToTrack).includes(splitImpressionType)) {
    elementsToTrack[splitImpressionType] = []
  }

  if (!Object.keys(readyToTrack).includes(splitImpressionType)) {
    readyToTrack[splitImpressionType] = []
  }
}

//Split - setting up tracking data
const setSplitImpressionsData = (elementSplitImpressionType, ele) => {
  readyToTrack[elementSplitImpressionType].push(ele)
}

// sets impressionType
const setImpressionType = obj => {
  impressionType = obj.impressionType
    ? impressionTypes[obj.impressionType]
    : impressionTypes.PRODUCT_IMPRESSION
  splitImpressionType = obj.splitImpressionType
    ? impressionTypes[obj.splitImpressionType]
    : impressionTypes.SPLIT_PRODUCT_IMPRESSION

  if (!Object.keys(elementsToTrack).includes(impressionType)) {
    elementsToTrack[impressionType] = []
  }

  if (!Object.keys(trackedElements).includes(impressionType)) {
    trackedElements[impressionType] = []
  }

  if (!Object.keys(pendingToTrackCount).includes(impressionType)) {
    pendingToTrackCount[impressionType] = 0
  }

  if (!Object.keys(readyToTrack).includes(impressionType)) {
    readyToTrack[impressionType] = []
  }

  //for these to types we don't set impressions type in memory
  //as these are a subset of the main impressions types
  setCitrusTrackingObjects(impressionType)
  setSplitTrackingObjects(splitImpressionType)
}

/**
 * sets readyToTrack data as per impression type
 *
 * looping through all the impression types since
 * there could exist 2 types of impressions on a
 * given page
 */
const setImpressionData = (
  elementRefId,
  elementImpressionType,
  elementSplitImpressionType,
  retriggerImpressioEnabled
) => {
  // do not continue if element present in readyToTrack queue
  if (
    !retriggerImpressioEnabled &&
    readyToTrack[elementImpressionType] &&
    readyToTrack[elementImpressionType].find(ele => ele.refId === elementRefId)
  )
    return

  // find in elementsToTrack & push to readyToTrack queue
  if (elementsToTrack[elementImpressionType]) {
    elementsToTrack[elementImpressionType].find(ele => {
      if (ele.refId === elementRefId) {
        readyToTrack[elementImpressionType].push(ele)
        if (ele?.adId) {
          setCitrusImpressionsData(elementImpressionType, ele.adId)
        }
      }
    })
  }

  // find in elementsToTrack & push to readyToTrack queue
  if (
    elementSplitImpressionType &&
    elementsToTrack[elementSplitImpressionType]
  ) {
    elementsToTrack[elementSplitImpressionType].find(ele => {
      if (ele.refId === elementRefId) {
        if (ele?.event_type === SPLIT_EVENT) {
          setSplitImpressionsData(elementSplitImpressionType, ele)
        }
      }
    })
  }

  // once threshold for current impressionType is hit
  // publish event to trigger tracking from component
  Object.keys(readyToTrack).forEach(type => {
    if (
      ![
        impressionTypes.CITRUS_BANNER_IMPRESSION,
        impressionTypes.CITRUS_PRODUCT_IMPRESSION,
      ].includes(type)
    ) {
      if (hasReachedPerEventItemThreshold(type)) {
        triggerImpressionsTracking.publish(type, type)
      }
    }
  })
}

// returns data to track as per impression type
const getImpressionData = type => {
  setImpressionType({ impressionType: type })
  return readyToTrack[impressionType]
}

// clears tracked data as per impression type
const clearTrackedData = type => {
  setImpressionType({ impressionType: type })

  //used to set count that helps in decision to publish event to track
  const pendingToTrack = differenceBy(
    elementsToTrack[impressionType],
    readyToTrack[impressionType],
    'refId'
  )
  elementsToTrack[impressionType] = pendingToTrack
  //maintains tracked elements per impression type
  //used when store change / page chanage occurs for the same elements
  trackedElements[impressionType] = trackedElements[impressionType].concat(
    readyToTrack[impressionType]
  )
  pendingToTrackCount[impressionType] = pendingToTrack.length || 0
  readyToTrack[impressionType] = []
}

/**
 *  all operations are based on impression type
 *  maintains a collection of elements eligible for tracking
 *
 * 1. checks if already existing element then update data
 * 2. check in elementsToTrack queue, if found return
 * 3. otherwise push to elementsToTrack queue
 * 4. update pendingToTrackCount count for corresponding impression type
 */
const setReferenceTrackingData = (
  productTrackingData = {},
  itemImpressionType = ''
) => {
  const currentItemImpressionType = itemImpressionType.length
    ? itemImpressionType
    : impressionType
  const updatedReferenceTrackingData = updateReferenceTrackingData(
    productTrackingData,
    currentItemImpressionType
  )
  if (!updatedReferenceTrackingData) {
    if (
      elementsToTrack[currentItemImpressionType].length >
      collectionThreshold[currentItemImpressionType]
    ) {
      elementsToTrack[currentItemImpressionType] = []
    }

    if (
      elementsToTrack[currentItemImpressionType].filter(
        i =>
          i.refId === productTrackingData.refId &&
          ((productTrackingData.creative &&
            i.creative === productTrackingData.creative) ||
            (productTrackingData.list && i.list === productTrackingData.list))
      ).length
    )
      return
    elementsToTrack[currentItemImpressionType].push(productTrackingData)
    pendingToTrackCount[currentItemImpressionType] =
      elementsToTrack[currentItemImpressionType].length
  }
}

const setObserver = ({
  elementRef,
  callback,
  observerOptions = { root: null, rootMargin: '0px', threshold: 0.01 },
}) => {
  if (
    typeof window !== 'undefined' &&
    'IntersectionObserver' in window &&
    'IntersectionObserverEntry' in window &&
    'intersectionRatio' in window.IntersectionObserverEntry.prototype
  ) {
    const observer = new IntersectionObserver(callback, observerOptions)
    observer.observe(elementRef)
  } else {
    import('intersection-observer').then(() => {
      const observer = new IntersectionObserver(callback, observerOptions)
      observer.observe(elementRef)
    })
  }
}

/**
 * Creates observer for productRef passed in obj
 * sets referenceTrackingData
 *
 * default root: null (track elements in viewport), can pass specific element as root
 * default threshold: 0.5 (tracks when minimum 50% element is in viewport), valid values 0 to 1.0
 * setting default trackType to TRACK_ONCE per page view
 */
const createNewObserver = (obj = {}) => {
  if (obj.productRef) {
    setImpressionType(obj)
    if (
      obj?.productTrackingData &&
      obj?.productTrackingData.event_type !== SPLIT_EVENT
    ) {
      setReferenceTrackingData(obj?.productTrackingData, obj?.impressionType)
    }
    if (
      obj?.splitTrackingData &&
      obj?.splitTrackingData.event_type === SPLIT_EVENT
    ) {
      setReferenceTrackingData(obj?.splitTrackingData, obj?.splitImpressionType)
    }
    const observerOptions = obj.options
      ? obj.options
      : { root: null, rootMargin: '0px', threshold: [0.5,0] }
    const callbackFn = obj.trackType
      ? callbackFnList[obj.trackType]
      : callbackFnList[trackingTypes.TRACK_ONCE]
    setObserver({
      elementRef: obj.productRef,
      callback: callbackFn,
      observerOptions: observerOptions,
    })
  }
}

/**
 * @param {*} productTrackingDataObj
 * 1. removes matching element from trackedElements collection
 *    pushes to elementsToTrack queue
 * 2. returns boolean isUpdated indicating queue updated / not
 */
const updateReferenceTrackingData = (
  productTrackingDataObj = {},
  currentItemImpressionType = impressionType
) => {
  let isUpdated = false

  const updateTracked =
    remove(
      trackedElements[currentItemImpressionType],
      productTrackingDataObj
    ) || []
  if (updateTracked.length) {
    elementsToTrack[currentItemImpressionType].push(productTrackingDataObj)
    isUpdated = true
  } else {
    elementsToTrack[currentItemImpressionType].find((i, index) => {
      if (i.refId === productTrackingDataObj.refId) {
        elementsToTrack[currentItemImpressionType][index] =
          productTrackingDataObj
        isUpdated = true
      }
    })
  }
  pendingToTrackCount[currentItemImpressionType] =
    elementsToTrack[currentItemImpressionType].length
  return isUpdated
}

export {
  trackingTypes,
  impressionTypes,
  getImpressionData,
  clearTrackedData,
  createNewObserver,
  triggerImpressionsTracking,
  checkpoints,
  setObserver,
}
