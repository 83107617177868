import getConfig from 'next/config'
import {
  impressionTypes,
  getImpressionData,
  clearTrackedData,
} from './impressionsTracker'
import { post, headers } from './fetch'
import isEmpty from 'lodash/isEmpty'
import compact from 'lodash/compact'
import SessionStorageService from '../utils/SessionStorageService'
const {
  publicRuntimeConfig: { API_URL },
} = getConfig()

const getXSessionId = () => {
  const sessionId = SessionStorageService.getItem('tt_sessionId')
  if (sessionId) {
    return sessionId.replace(/"/g, '');
  } else {
    return null
  }
}

const headersForCitrus = () => {
  const sessionId = getXSessionId()
  return {
    ...headers(),
    ...(sessionId ? { 'x-session-id': sessionId } : {}),
  }
}
const isCitrusProductTrackingEnabled = (remoteConfig = {}) => {
  let citrusConfig = remoteConfig?.citrusAdConfig?.defaultValue?.value || {}
  citrusConfig =
    typeof citrusConfig === 'string' ? JSON.parse(citrusConfig) : citrusConfig

  return !!citrusConfig?.enableProductTracking || false
}

const trackCitrusAdProductClick = (adIds = [], remoteConfig = {}) => {
  const validAdIds = compact(adIds).map(String)
  if (!isEmpty(validAdIds) && isCitrusProductTrackingEnabled(remoteConfig)) {
    const requestUrl = `${API_URL}/marketing/tracking`
    post(requestUrl, {
      headers: headersForCitrus(),
      body: JSON.stringify({
        type: 'Clicks',
        adIds: validAdIds,
        platform: 'web',
      }),
    })
  }
}

const trackCitrusAdProduct = (remoteConfig = {}) => {
  if (isCitrusProductTrackingEnabled(remoteConfig)) {
    const citrusProductImpressionData = getImpressionData(
      impressionTypes.CITRUS_PRODUCT_IMPRESSION
    )

    if (citrusProductImpressionData.length) {
      const requestUrl = `${API_URL}/marketing/tracking`
      post(requestUrl, {
        headers: headersForCitrus(),
        body: JSON.stringify({
          type: 'Impressions',
          adIds: citrusProductImpressionData,
          platform: 'web',
        }),
      })
      clearTrackedData(impressionTypes.CITRUS_PRODUCT_IMPRESSION)
    }
  }
}

const trackCitrusBannerClick = ({ banner }) => {
  const requestUrl = `${API_URL}/marketing/tracking`
  post(requestUrl, {
    headers: headersForCitrus(),
    body: JSON.stringify({
      type: 'Clicks',
      adIds: [banner.adId.toString()],
      platform: 'web',
    }),
  })
}

const trackCitrusBannerImpressions = () => {
  const citrusBannerImpressionData = getImpressionData(
    impressionTypes.CITRUS_BANNER_IMPRESSION
  )
  if (citrusBannerImpressionData.length) {
    const requestUrl = `${API_URL}/marketing/tracking`
    post(requestUrl, {
      headers: headersForCitrus(),
      body: JSON.stringify({
        type: 'Impressions',
        adIds: citrusBannerImpressionData,
        platform: 'web',
      }),
    })
    clearTrackedData(impressionTypes.CITRUS_BANNER_IMPRESSION)
  }
}

export {
  getXSessionId,
  headersForCitrus,
  isCitrusProductTrackingEnabled,
  trackCitrusAdProductClick,
  trackCitrusAdProduct,
  trackCitrusBannerClick,
  trackCitrusBannerImpressions,
}
